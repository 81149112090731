import { Link } from "react-router-dom";

export default function CareedCard({ title, image, id }) {
	return (
		<div className="career-card">
			<div className="career-image">
				<img src={image} alt="career-logo" />
			</div>
			<div>
				<p className="career-title">{title}</p>
			</div>
			<div>
				<Link to={`/career/${id}`}>
					<button
						className="career-button"
						style={
							{
								// backgroundColor: "transparent",
								// padding: "3px 4px",
								// border: "1px solid #b3b3b3",
								// borderRadius: "5px",
							}
						}
					>
						View Details
					</button>
				</Link>
			</div>
		</div>
	);
}
