import React from "react";

function AnimateLine() {
  return (
    <>
      <div className="line_wrap">
        <div className="line_item" />
        <div className="line_item" />
        <div className="line_item" />
        <div className="line_item" />
        <div className="line_item" />
      </div>
    </>
  );
}

export default AnimateLine;
