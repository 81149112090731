import React from "react";
import ServiceCard from "../service/ServiceCard";
import {
  CapacityBuildingIcon,
  DesignAndImplementationIcon,
  PolicyAndRegulatoryIcon,
  ProjectDevelopmentIcon,
  ResearchAndSupportIcon,
  TransactionAdvisoryAndProcurementIcon,
} from "../../svgs";

export default function InternalMarketingSerice() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image={<ProjectDevelopmentIcon width={80} height={80} />}
                title="Project Development"
                details="Our range of services encompasses feasibility studies, financial analysis, risk assessment, demand forecasting, EIA, SIA, business planning, resettlement support, project appraisal, and specialized consulting services.
     

                "
                readMe={true}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image={<CapacityBuildingIcon width={80} height={80} />}
                title="Capacity Building"
                details="We conduct training needs assessments, capacity building, and awareness programs, and provide training for government officials and private sector professionals to help them enhance their skills and knowledge.
                "
                readMe={true}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image={
                  <TransactionAdvisoryAndProcurementIcon
                    width={80}
                    height={80}
                  />
                }
                title="Transaction Advisory and Procurement"
                details="Specialize in developing PPP projects, preparing tender documents (RFQ/RFP/IM, concession agreement), managing the bid process, and providing negotiation support for infrastructure projects.
                "
                readMe={true}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image={<ResearchAndSupportIcon width={80} height={80} />}
                title="Research and Support"
                details="Conduct studies and research on the infrastructure sector to inform project design and decision-making.
                "
                readMe={true}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image={<PolicyAndRegulatoryIcon width={80} height={80} />}
                title="Policy and Regulatory"
                details="Let's work on preparing guidelines, creating policies, establishing legal and regulatory frameworks, reorganizing institutional and organizational structures, and developing organizational frameworks.

                "
                readMe={true}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image={<DesignAndImplementationIcon width={80} height={80} />}
                title="Design and Implementation"
                details="Provide detailed design, construction, supervision, project implementation support, and related services for infrastructure development throughout the entire project lifecycle.
                "
                readMe={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
