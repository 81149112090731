import React from "react";

function SidebarWidget() {
	return (
		<>
			{/* <div className="sidebar-widget">
          <div className="client-box">
            <span>Client:</span>
            <h5>Argova Josen</h5>
          </div>
          <div className="client-box">
            <span>Company:</span>
            <h5>Egens Theme</h5>
          </div>
          <div className="client-box">
            <span>Location:</span>
            <h5>7 Lake Street, London</h5>
          </div>
          <div className="client-box">
            <span>Project Type:</span>
            <h5>Desktop Mockup</h5>
          </div>
          <div className="client-box">
            <span>Duration:</span>
            <h5>6 Month</h5>
          </div>
        </div> */}
			<div className="sidebar-widget">
				<div className="contact-info">
					<h3>Need help?</h3>
					<p>
						Transforming Ideas into Reality by Crafting Software, Websites, and
						Successful Launches.
					</p>
					{/* <div className="cmpy-info">
            <div className="icon">
              <i className="fas fa-map-marker-alt" />
            </div>
            <div className="cnt">
              <h5>Location</h5>
              <p>Macro Lily, Plot 8/2, Block D, Lalmatia, Dhaka - 1207</p>
            </div>
          </div> */}
					<div className="cmpy-info">
						<div className="icon">
							<i className="fas fa-phone-alt" />
						</div>
						<div className="cnt">
							<h5>Phone</h5>
							<a href="tel:8801790092209">+88 01790092209</a>
							<a href="tel:16143000676">+1614-300-0676</a>
						</div>
					</div>
					<div className="cmpy-info">
						<div className="icon">
							<i className="far fa-envelope" />
						</div>
						<div className="cnt">
							<h5>Email</h5>
							<a href="mailto:support@vector360bd.com">
								support@vector360bd.com
							</a>
						</div>
					</div>
					<div className="cmpy-info">
						<div
							className="icon"
							style={{
								display: "flex",
							}}
						>
							<i className="bi bi-whatsapp" style={{ fontSize: "25px" }}></i>
						</div>
						<div className="cnt">
							<h5>Whats App</h5>
							<a
								href="https://wa.me/+8801790092209 "
								target="_blank"
								rel="noopener noreferrer"
							>
								+880 1790092209
							</a>
							<a
								href="https://wa.me/+16143000676"
								target="_blank"
								rel="noopener noreferrer"
							>
								+1614-300-0676
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SidebarWidget;

// <div className="single-info">
// <div
//   className="icon"
//   style={{
//     display: "flex",
//   }}
// >
//   <i
//     className="bi bi-whatsapp"
//     style={{
//       fontSize: "25px",
//     }}
//   ></i>
// </div>
// <div className="info">
//   <h3>Whatsapp</h3>
//   <a
//     href="https://wa.me/+8801790092209 "
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     +880 1790092209
//   </a>
//   <a
//     href="https://wa.me/+16143000676"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     +1614-300-0676
//   </a>
//   {/* <a href="tell:support@vector360bd.com">support@vector360bd.com</a> */}
// </div>
// </div>
