import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { careerData } from "../../data/CareerData";
import { jdData } from "../../data/jdData";

export default function JdView({ id }) {
	// jdData

	return (
		<div
			className="container"
			style={{
				paddingTop: "30px",
			}}
		>
			<div className="career-view-wrapper">
				<div className="career-right-side">
					{jdData?.map((item) => {
						if (item.id == id && item.STATUS) {
							return (
								<>
									<div>
										<div>
											<h2>{item.TITLE}</h2>
										</div>
										<div>
											<p
												style={{
													textAlign: "justify",
												}}
											>
												{item.POSITION_OVERVIEW}
											</p>
										</div>
										{item.RESPONSIBILITIES && (
											<div
												style={{
													paddingTop: "10px",
												}}
											>
												<h5>Job Responsibilities:</h5>
												{/* <p>{item.RESPONSIBILITIES}</p> */}
												{item.RESPONSIBILITIES?.split("•").map(
													(responsibility, index) =>
														responsibility ? (
															<p key={index} style={{ padding: "0px 0 2px 0" }}>
																•{responsibility}
															</p>
														) : null
												)}
											</div>
										)}
										{item.EDUCATION_EXPERIENCE && (
											<div
												style={{
													paddingTop: "10px",
												}}
											>
												<h5>Educational and Experience Requirements:</h5>
												{/* <p>{item.RESPONSIBILITIES}</p> */}
												{item.EDUCATION_EXPERIENCE?.split("•").map(
													(experience, index) =>
														experience ? (
															<p key={index} style={{ padding: "0px 0 2px 0" }}>
																•{experience}
															</p>
														) : null
												)}
											</div>
										)}
										{item.REQUIRMENTS && (
											<div
												style={{
													paddingTop: "10px",
												}}
											>
												<h5>Skills:</h5>
												{/* <p>{item.RESPONSIBILITIES}</p> */}
												{item.REQUIRMENTS?.split("•").map((requirment, index) =>
													requirment ? (
														<p key={index} style={{ padding: "0px 0 2px 0" }}>
															•{requirment}
														</p>
													) : null
												)}
											</div>
										)}
										{item.JOB_TYPE && (
											<div
												style={{
													paddingTop: "15px",
												}}
											>
												<h5>Type:</h5>
												<p>{item.JOB_TYPE}</p>
											</div>
										)}
										{item.SALARY && (
											<div
												style={{
													paddingTop: "10px",
												}}
											>
												<h5>Salary:</h5>
												<p>{item.SALARY}</p>
											</div>
										)}
										{item.HOLIDAY && (
											<div
												style={{
													paddingTop: "10px",
												}}
											>
												<h5>Holiday:</h5>
												<p>{item.HOLIDAY}</p>
											</div>
										)}
									</div>
								</>
							);
						}
					})}
				</div>
				<div className="career-left-wrapper">
					<div>
						{jdData?.map((item) => {
							if (item.id == id && item.STATUS)
								return <img src={item.IMAGE} alt="job img" />;
						})}
					</div>
					<div className="career-left-wrapper-box">
						<div>
							{jdData?.map((item) => {
								if (item.id == id && item.STATUS)
									return (
										<a href={item.APPLY_LINK}>
											<button
												style={{
													backgroundColor: "#ffcb05",
													border: "none",
													width: "100%",
													padding: "5px 0",
													fontWeight: "600",
													borderRadius: "5px",
												}}
											>
												Apply Now
											</button>
										</a>
									);
							})}
						</div>
						<div>
							<p
								style={{
									color: "red",
									fontWeight: 500,
								}}
							>
								Application Deadline
							</p>
							<p
								style={{
									color: "red",
								}}
							>
								{jdData?.map((item) => {
									if (item.id == id && item.STATUS)
										return <p>{item.APPLICATION_DEADLINE}</p>;
								})}
							</p>
						</div>
						<div>
							<p style={{ color: "#ffcb05", fontWeight: 500 }}>Department</p>
							{jdData?.map((item) => {
								if (item.id == id && item.STATUS)
									return <p>{item.DEPARTMENT}</p>;
							})}
						</div>
						<div>
							<p style={{ color: "#ffcb05", fontWeight: 500 }}>
								Employment Type:
							</p>
							{jdData?.map((item) => {
								if (item.id == id && item.STATUS) return <p>{item.JOB_TYPE}</p>;
							})}
						</div>
						<div>
							<p style={{ color: "#ffcb05", fontWeight: 500 }}>location:</p>
							<p>Dhaka</p>
						</div>
						<div>
							<p style={{ color: "#ffcb05", fontWeight: 500 }}>Salary Range:</p>
							{jdData?.map((item) => {
								if (item.id == id && item.STATUS) return <p>{item.SALARY}</p>;
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
