import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import InternalMarketingWrap from "./InternalMarketingWrap";
export default function InternalMarketing() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb
          pageName="Internal Marketing"
          pageTitle="Internal Marketing"
        />
        <InternalMarketingWrap />
        <SubscribArea />
      </div>
    </>
  );
}
