import React from "react";

function ServiceDetailsItems() {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>01</span>Website Development
                </h4>
                <p>
                  Achieve online success with our top-notch website development
                  services. Vector360 excels in crafting visually appealing and
                  user-friendly websites, leveraging cutting-edge technologies
                  and Agile methodologies. We prioritise client objectives,
                  ensuring online success through innovative design and seamless
                  functionality. Our team specialises in custom website
                  development, delivering tailored solutions that meet the
                  unique needs of businesses across various industries. Let us
                  unlock the potential of your online presence with our
                  top-notch website development services.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-1.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-2.jpg"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>02</span>Mobile App Development
                </h4>
                <p>
                  Unlock the full potential of your business with our
                  professional mobile app development services. Vector360
                  specialises in professional mobile app development services,
                  unlocking the full potential of businesses and ideas. Our
                  Agile approach ensures adaptability and transparency
                  throughout the development process. From concept to launch, we
                  focus on creating high-performing mobile applications that
                  meet client objectives and exceed user expectations. Let us
                  bring your app idea to life with our expertise and commitment
                  to excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>03</span>Tech Recruiting Agency
                </h4>
                <p>
                  Empowering tech careers through tailored connections, discover
                  top-tier opportunities with our specialised recruiting agency
                  service. Vector360 operates a premier tech recruiting agency,
                  connecting top-tier talent with innovative opportunities. Our
                  tailored approach empowers tech careers, matching candidates
                  with roles that align with their skills and aspirations.
                  Whether seeking talented professionals or exploring new career
                  avenues, our specialised recruiting services ensure a seamless
                  experience. Trust Vector360 to navigate the tech industry
                  landscape and unlock your potential for success.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-3.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img
                  src={
                    process.env.PUBLIC_URL + "/img/blog/software-testing.png"
                  }
                  alt=""
                  height={480}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>04</span>Software Testing
                </h4>
                <p>
                  Ensure the quality and reliability of your software with our
                  professional testing services. Vector360 offers professional
                  software testing services to ensure the quality and
                  reliability of your software. Our expert team conducts
                  thorough testing to identify and resolve issues, providing a
                  seamless user experience. With our meticulous approach and
                  attention to detail, you can trust us to deliver high-quality
                  software that meets your standards and exceeds expectations.
                  Let us safeguard your software's performance and reputation
                  with our comprehensive testing solutions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>05</span>UI/UX Design
                </h4>
                <p>
                  Improve user engagement and boost conversions with our
                  professional UI/UX design services. Vector360 specialises in
                  professional UI/UX design services to enhance user engagement
                  and boost conversions. Our team creates visually appealing and
                  intuitive interfaces tailored to your audience's needs. By
                  focusing on user-centric design principles, we ensure a
                  seamless and enjoyable experience across web and mobile
                  platforms. Elevate your digital presence with our expert UI/UX
                  design solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/ui-ux.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/branding.jpeg"}
                  alt=""
                  height={480}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>06</span>Branding
                </h4>
                <p>
                  Vector360 offers professional branding services to help
                  establish a strong brand identity for your business. Our team
                  crafts cohesive branding strategies that resonate with your
                  target audience and differentiate you from competitors. From
                  logo design to brand messaging, we ensure consistency across
                  all touchpoints. Let us elevate your brand presence and create
                  lasting connections with customers through strategic branding
                  initiatives tailored to your unique vision and goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>07</span>Customer care management
                </h4>
                <p>
                Enhance your customer experience and streamline operations with Vector360 expert Business Process Outsourcing through our dedicated call center services."
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/customer-care.webp"}
                  alt=""
                  
                />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/social-impact.webp"}
                  alt=""
                  height={480}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>08</span>Environmental and Social Impact Assessment
                </h4>
                <p>
                Explore our tailored Environmental and Social Impact Assessment solutions, designed to address both environmental conservation and community welfare for a holistic approach to project development.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details">
                <h4>
                  <span>09</span>Environmental impact assessment
                </h4>
                <p>
                Vector360BD offers comprehensive Environmental Impact Assessment services, ensuring sustainable development and environmental compliance for your projects.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/customer-care.webp"}
                  alt=""
                  
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsItems;
