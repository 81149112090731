import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";
import { SoftwareDevelopmentIcon } from "../../svgs";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/service.jpg"}
                  alt="images service"
                />
                <h3>
                  <i>
                    <SoftwareDevelopmentIcon width={80} height={80} />
                  </i>
                  Software Development
                </h3>
                <p className="text-justify">
                  Our cutting-edge software development services will help you
                  take your company to the next level. Elevate your company's
                  trajectory with our innovative software development solutions.
                  Our cutting-edge services are meticulously crafted from
                  conceptualisation to implementation to propel your business to
                  new heights. Whether you're seeking custom software solutions
                  or revamping existing systems, our expert team is dedicated to
                  delivering excellence tailored to your needs. With a focus on
                  efficiency, scalability, and user-centric design, we ensure
                  that your software meets and exceeds expectations, empowering
                  you to thrive in today's dynamic digital landscape.
                </p>
                <p className="text-justify">
                  In the fast-paced world of modern business, having the right
                  software solutions can make all the difference. At Vector360
                  BD, we specialize in providing cutting-edge software
                  development services designed to propel your company to new
                  heights. Our services are meticulously crafted from
                  conceptualization to implementation, ensuring that every step
                  is tailored to meet your unique needs and goals.
                </p>
                <p className="text-justify">
                  Whether you're a startup looking to establish a strong
                  technological foundation or an established enterprise aiming
                  to revamp your existing systems, our expert team is dedicated
                  to delivering excellence. We understand that one size does not
                  fit all in the world of software, which is why we focus on
                  creating custom solutions that align perfectly with your
                  business objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Web Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Apps Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Software Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Motion Graphics
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      UI/UX Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/project`}
                      onClick={scrollTop}
                    >
                      Graphic Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar-banner">
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:8801790092209">+88 01790092209</a>
                </div>
              </div>
            </div>
          </div>
          <ServiceDetailsItems />
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
