export const jdData = [
	{
		id: 1,
		STATUS: false,
		TITLE: "Node.js Developer",
		DEPARTMENT: "Information Technology",
		IMAGE: "/career/image1.png",
		POSITION_OVERVIEW: `Vector360BD is looking for an experienced Node.js developer with a minimum of 3+ years of experience in Javascript.
        We are looking for a highly capable PHP developer to optimize our web-based application performance. You will be
        collaborating with our front-end application developers, designing back-end components, and integrating data storage and
        protection solutions.`,
		RESPONSIBILITIES: `• Developing and maintaining all server-side components.
                • Ensuring optimal performance of the central database and      responsiveness to frontend requests.
                • Collaborating with frontend developers on the integration of elements.
                • Developing high-performance applications by writing testable, reusable, and efficient code.
                • Implementing effective security protocols, data protection measures, and storage solutions.`,
		REQUIRMENTS: `• Ensuring optimal performance of the central database and      responsiveness to frontend requests.
                • Collaborating with frontend developers on the integration of elements.
                • Developing high-performance applications by writing testable, reusable, and efficient code.
                • Implementing effective security protocols, data protection measures, and storage solutions. • Ensuring optimal performance of the central database and      responsiveness to frontend requests.
                • Collaborating with frontend developers on the integration of elements.
                • Developing high-performance applications by writing testable, reusable, and efficient code.
                • Implementing effective security protocols, data protection measures, and storage solutions. • Ensuring optimal performance of the central database and      responsiveness to frontend requests.
                • Collaborating with frontend developers on the integration of elements.
                • Developing high-performance applications by writing testable, reusable, and efficient code.
                • Implementing effective security protocols, data protection measures, and storage solutions.`,
		EDUCATION_EXPERIENCE: `• Bachelor of Science (BSc) in CSE
                                3+ years of experience.
                            • 2-years or 3- years working experience in back-end web development with Laravel.`,
		BENEFITS: `• Festival Bonus: 2. • As Per Company policy`,
		JOB_TYPE: `Full Time | Onsite`,
		SALARY: `Negotiable`,
		HOLIDAY: `Weekely 2 holidays (Thursday and Friday)`,
		APPLICATION_DEADLINE: "Sep 10, 2025",
		APPLY_LINK:
			"https://docs.google.com/forms/d/1MMsXRQL-QohE2Cqx5HPHLFXdTpRlAluHtR1ExsasjOU/edit?ts=66e94218",
	},
	{
		id: 2,
		STATUS: false,
		POSITION_OVERVIEW: ``,
		RESPONSIBILITIES: ``,
		REQUIRMENTS: ``,
		EDUCATION_EXPERIENCE: ``,
		BENEFITS: ``,
		JOB_TYPE: ``,
		SALARY: ``,
		HOLIDAY: ``,
		APPLY_LINK: "",
	},
];
