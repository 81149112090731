import React, { useState, useEffect } from "react";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import CareedCard from "./CareedCard";
import { careerData } from "./data/CareerData";

SwiperCore.use([Navigation, Autoplay]);

function CareerSlider() {
	return (
		<>
			<section className="container">
				{careerData?.map((item, index) => {
					// Generate unique classes for each section
					const prevButtonClass = `swiper-button-prev-c-${index}`;
					const nextButtonClass = `swiper-button-next-c-${index}`;

					// Configuration for each Swiper slider
					const careerSlider = {
						spaceBetween: 20,
						speed: 1000,
						navigation: {
							nextEl: `.${nextButtonClass}`, // Unique class for next button
							prevEl: `.${prevButtonClass}`, // Unique class for previous button
						},
						breakpoints: {
							320: {
								slidesPerView: 2,
							},
							576: {
								slidesPerView: 3,
							},
							992: {
								slidesPerView: 4,
							},
							1200: {
								slidesPerView: 5,
							},
						},
					};

					// Check if there are any open jobs in this section
					const hasOpenJob = item.openingJob.some((job) => job.isOpen);

					return (
						<div key={item.id}>
							<div>
								<div className="career-title-section">
									<div>
										<h1>{item.sectionTitle}</h1>
									</div>
									<div className="career-navigation-button">
										{/* Unique navigation buttons for each section */}
										<div className={prevButtonClass}>
											<i className="bi bi-chevron-left" />
										</div>
										<div className={nextButtonClass}>
											<i className="bi bi-chevron-right" />
										</div>
									</div>
								</div>
							</div>
							<div>
								<Swiper {...careerSlider} className="swiper partner-slider">
									<div className="swiper-wrapper">
										{item?.openingJob?.map((value) => {
											if (value.isOpen) {
												return (
													<SwiperSlide
														className="career-swiper-slide"
														key={value.id}
													>
														<CareedCard {...value} />
													</SwiperSlide>
												);
											}
											return null; // Don't return anything if the job isn't open
										})}
									</div>
								</Swiper>
								{/* Display the message for sections with no open jobs */}
								{!hasOpenJob && (
									<h6>
										{item.openingJob[0]?.status ||
											"Currently no opportunity available"}
									</h6>
								)}
							</div>
						</div>
					);
				})}
			</section>
		</>
	);
}

export default CareerSlider;
