import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
	const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
	return (
		<>
			<footer className={props.footerAddclass}>
				<div className="container">
					<div className="footer-top">
						<div className="row">
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<div className="footer-logo">
										<Link scrollTo={scrollTop} to={"/"}>
											<img
												src={process.env.PUBLIC_URL + "/img/white-logo.svg"}
												alt="FooterImg"
												height={100}
											/>
										</Link>
									</div>
									<p>
										UNLOCK THE POWER OF INNOVATION WITH OUR SOFTWARE SOLUTIONS
									</p>
									<ul className="social-media-icons">
										{/* <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li> */}
										<li>
											<a href="https://www.linkedin.com/company/vector360bd">
												{/* <i className="fab fa-twitter" /> */}
												<i className="bi bi-linkedin"></i>
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/vector360.bd">
												<i className="bi bi-instagram"></i>
											</a>
										</li>
										<li>
											<a href="https://www.facebook.com/people/Vector360/61562323470367">
												<i className="bi bi-facebook"></i>
											</a>
										</li>
										{/* <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li> */}
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Our Services</h4>
									<ul className="footer-menu">
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Strategy &amp; Research
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Web Development
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Web Solution
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector Digital Marketing
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector App Design
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service-details`}
												onClick={scrollTop}
											>
												Vector App Development
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Quick Links</h4>
									<ul className="footer-menu">
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/about`}
												onClick={scrollTop}
											>
												About Vector
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/service`}
												onClick={scrollTop}
											>
												Vector Services
											</Link>
										</li>
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/project`}
												onClick={scrollTop}
											>
												Vector Project
											</Link>
										</li>
										{/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Blog</Link>
                    </li> */}
										<li>
											<Link
												to={`${process.env.PUBLIC_URL}/career`}
												onClick={scrollTop}
											>
												Vector Career
											</Link>
										</li>
										{/* <li>
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Pricing Plan</Link>
                    </li> */}
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-lg-3 col-xl-3">
								<div className="footer-widget">
									<h4>Contacts</h4>
									<div className="number">
										<div className="num-icon">
											<i className="fas fa-phone-alt" />
										</div>
										<div className="phone">
											<a href="tel:8801790092209">+880 1790092209</a>
											<a href="tel:16143000676">+1614-300-0676</a>
										</div>
									</div>
									<div className="office-mail">
										<div className="mail-icon">
											<i className="far fa-envelope" />
										</div>
										<div className="email">
											<a href="tell:support@vector360bd.com">
												support@vector360bd.com
											</a>
										</div>
									</div>
									<div className="number">
										<div className="num-icon">
											<i className="bi bi-whatsapp" />
										</div>
										<div className="phone">
											<a
												href="https://wa.me/+8801790092209 "
												target="_blank"
												rel="noopener noreferrer"
											>
												+880 1790092209
											</a>
											<a
												href="https://wa.me/+16143000676"
												target="_blank"
												rel="noopener noreferrer"
											>
												+1614-300-0676
											</a>
										</div>
									</div>
									{/* https://wa.me/1XXXXXXXXXX */}
									{/* <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Macro Lily, Plot 8/2, Block D, Lalmatia, Dhaka - 1207</p>
                  </div> */}
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="row align-items-center">
							<div className="col-12 col-md-4 col-lg-4 col-xl-5">
								<div className="copy-txt">
									<span>
										© Copyright {new Date().getFullYear()} <b>Vector360 BD</b>
										{/* Design By <Link to={"#"}>Egens Lab</Link> */}
									</span>
								</div>
							</div>
							{/* <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={"#"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Terms of Use</Link>
                  </li>
                </ul>
              </div> */}
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
