import React from "react";

function WhyChooseUs() {
  return (
    <>
      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img
                    src={"/img/about/about-1.webp"}
                    alt="why-choose images"
                  />
                </div>
                <div className="choose-banner2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/about/about-2.webp"}
                    alt="why-choose images"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/img/about/about-3.webp"}
                    alt="why-choose images"
                  />
                </div>
                <div className="years">
                  <h5>4+</h5>
                  <span>Years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>
                    Transforming Ideas into Reality by Crafting Software,
                    Websites, and Successful Launches.
                  </h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">100</span>
                    <sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">160</span>
                    <sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">20</span>
                    <sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p className="text-justify">
                  Choose Vector360 for cutting-edge software solutions, agile
                  project management, and a commitment to excellence. With a
                  focus on client objectives, transparency, and collaboration,
                  Vector360 delivers high-performing mobile and web
                  applications. Benefit from top-notch services spanning
                  software development, website development, mobile app
                  development, UI/UX design, branding, and software testing.
                  Empower your business with transformative experiences and stay
                  ahead in today's competitive landscape. Unlock the power of
                  innovation with Vector360.
                </p>
                <div className="buttons-group">
                  <span>24/7 Support</span>
                  <span>Unique Design</span>
                  <span>Clean Code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
