import InternalMarketingDetails from "./InternalMarketingDetails";
import InternalMarketingSerice from "./InternalMarketingSerice";

export default function InternalMarketingWrap() {
  return (
    <>
      {" "}
      <InternalMarketingDetails />
      <InternalMarketingSerice />
    </>
  );
}
