import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Case Study</span>
              <h2>Project</h2>
              <p className="text-justify">
                Celebrate our journey of success with a glimpse into our
                completed projects. Each endeavor reflects our dedication to
                excellence, turning visions into realities for our valued
                clients. Explore our portfolio and discover the craftsmanship
                that defines us.
              </p>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
