import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import CareerWrapper from "./CareerWrapper";

export default function Career() {
	return (
		<>
			<div className="creasoft-wrap">
				<AnimateLine />
				<Breadcrumb pageTitle="Vector Career" pageName="Career" />
				<CareerWrapper />
				<SubscribArea />
			</div>
		</>
	);
}
