import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
  };
  return (
    <>
      <div className="project-details-content">
        <h3>
          <a
            href="https://reserveitbd.com/"
            target="_black"
            style={{ color: "black" }}
          >
            Reserveit BD
          </a>
        </h3>
        <div className="project-thumb">
          <img
            src={process.env.PUBLIC_URL + "/img/project/reserveit-banner.webp"}
            alt="mages"
          />
          <div className="tag">
            <Link
              to={`${process.env.PUBLIC_URL}/project-details`}
              onClick={window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Software
            </Link>
          </div>
        </div>
        <p className="text-justify " style={{ marginTop: "10px" }}>
          Reserveit specialises in elevating hospitality operations through
          bespoke websites and applications. Their offerings provide
          frictionless booking experiences and enhance operational efficiency in
          the restaurant sector. Prioritising intuitive design and
          functionality, Reserveit facilitates seamless customer and business
          interactions. Whether managing reservations or optimising workflows,
          their tailored solutions empower hospitality enterprises to streamline
          operations and deliver outstanding guest experiences. By leveraging
          cutting-edge technology and a deep understanding of the industry's
          needs, Reserveit is a reliable partner in advancing hospitality
          businesses, helping them thrive in a competitive market landscape.
        </p>
        <div className="clinet-need">
          <h4>Client Needs</h4>
          <p>✔️Accurate Information</p>
          <p>✔️Flexible Options</p>
          <p>✔️Reviews and Recommendations</p>
          <p>✔️Mobile Accessibility</p>
          <p>✔️Easy Booking Process</p>
        </div>
        <div className="working-process">
          <h4>Working Process</h4>

          <Swiper {...projectSlider} className="swiper work-process-slide">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/planning.webp"}
                    alt="mages"
                    style={{
                      objectFit: "cover",
                      height: "320px",
                    }}
                  />
                  <div className="label">
                    <span>01. Project Planning and Requirements Gathering</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/design.webp"}
                    alt="mages"
                  />
                  <div className="label">
                    <span>02. Design Phase</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/development.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>03. Development</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/testing.webp"}
                    alt="mages"
                  />
                  <div className="label">
                    <span>04. Testing</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/deployment.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span> 05. Deployment</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/monitoring.webp"
                    }
                    alt="mages"
                  />
                </div>
                <div className="label">
                  <span>06. Monitoring and Maintenance</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/improvement.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>07. Continuous Improvement</span>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="arrow">
              <div className="swiper-button-next-c">
                <i className="bi bi-arrow-right" />
              </div>
              <div className="swiper-button-prev-c">
                <i className="bi bi-arrow-left" />
              </div>
            </div>
          </Swiper>
          {/* <p>
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam
            eu nibh elementum, accumsan ona neque ac, aliquet nunc. In eu ipsum
            fringilla, accumsan purus vel, pellentesque risus. Vivamus vehicula
            nl purus at eros interdum, in dignissim nulla vestibulum. Nunc sit
            amet finibus felis, ut egestas lacus. Sedan pellentesque quis magna
            eu vestibulum. Ut sed commodo neque. Morbi erat nis
          </p> */}
        </div>
        {/* <div className="check-lunch">
          <h4>Check &amp; Launch</h4>
          <div className="row">
            <div className="col-lg-6">
              <ul className="check">
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Aenean vitae pharetra leo. Aliquam pri.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  In aliquet, quam vitae blandit temporg.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Aenean volutpat enim vitae tincidunta.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Felis at venenatis porttitor, nunc arcua.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Maximus felis quam vitae tortor phase.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Nulla vulputate urna quis ex pellentes.
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <p>
                Interdum et malesuada fames ac ante ipsum tobal primis in
                faucibus. Etiam eu nibh elementum, tolda accumsan ona In eu
                ipsum fringilla, accumsan pur neque ac, aliquet nunc. In eu
                ipsum fringilla, wolan accumsan purus vel, pellentesque risus.
                Vivamusa purus at eros interdum, in dignissim nulla Ut sedal
                vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan
                commodo neque. Morbi erat niswole pellentesque quis magna eu
                vestibulum.{" "}
              </p>
            </div>
          </div>
        </div> */}
      </div>

      {/* ---------- */}
      <hr />
      <div className="project-details-content" style={{ marginTop: "100px" }}>
        <h3>
          <a
            href="https://smartkrishok.com.bd/"
            target="_black"
            style={{ color: "black" }}
          >
            Smart Krishok
          </a>
        </h3>
        <div className="project-thumb">
          <img
            src={process.env.PUBLIC_URL + "/img/project/smart.webp"}
            alt="mages"
          />
          <div className="tag">
            <Link
              to={`${process.env.PUBLIC_URL}/project-details`}
              onClick={window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Software
            </Link>
          </div>
        </div>
        <p className="text-justify " style={{ marginTop: "10px" }}>
          Smart Krishok is a pioneering force in Bangladesh's agricultural
          sector, offering unparalleled digital support to enhance farming
          productivity and overcome industry obstacles. Specialising in
          agriculture, farming, fishing, and related sectors, Smart Krishok
          leverages cutting-edge technologies to develop advanced websites and
          applications. Their expertise lies in seamlessly integrating AI and
          graphic design to provide innovative solutions that empower businesses
          with competitive advantages. By prioritising innovation and
          excellence, Smart Krishok ensures clients stay ahead in the dynamic
          digital landscape of modern agriculture. Their impactful digital
          experiences enhance user engagement and drive growth, enabling farmers
          and businesses to thrive in an increasingly competitive environment.
          Smart Krishok's commitment to leveraging technology for the betterment
          of agriculture cements its position as a leader in digital support for
          the farming sector in Bangladesh.
        </p>
        <div className="clinet-need">
          <h4>Client Needs</h4>
          <p>✔️User-Friendly Interface</p>
          <p>✔️Customizable Solutions</p>
          <p>✔️Mobile Compatibility</p>
          <p>✔️Integration of AI</p>
          <p>✔️Data Security and Privacy</p>
          <p>✔️Scalability and Performance</p>
          <p>✔️Training and Support</p>
          <p>✔️Analytics and Reporting</p>
        </div>
        <div className="working-process">
          <h4>Working Process</h4>

          <Swiper {...projectSlider} className="swiper work-process-slide">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/planning.webp"}
                    alt="mages"
                    style={{
                      objectFit: "cover",
                      height: "320px",
                    }}
                  />
                  <div className="label">
                    <span>01. Project Planning and Requirements Gathering</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/design.webp"}
                    alt="mages"
                  />
                  <div className="label">
                    <span>02. Design Phase</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/development.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>03. Development</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/project/testing.webp"}
                    alt="mages"
                  />
                  <div className="label">
                    <span>04. Testing</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/deployment.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span> 05. Deployment</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/monitoring.webp"
                    }
                    alt="mages"
                  />
                </div>
                <div className="label">
                  <span>06. Monitoring and Maintenance</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide" style={{ height: "50px" }}>
                <div className="work-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/project/improvement.webp"
                    }
                    alt="mages"
                  />
                  <div className="label">
                    <span>07. Continuous Improvement</span>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="arrow">
              <div className="swiper-button-next-c">
                <i className="bi bi-arrow-right" />
              </div>
              <div className="swiper-button-prev-c">
                <i className="bi bi-arrow-left" />
              </div>
            </div>
          </Swiper>
          {/* <p>
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam
            eu nibh elementum, accumsan ona neque ac, aliquet nunc. In eu ipsum
            fringilla, accumsan purus vel, pellentesque risus. Vivamus vehicula
            nl purus at eros interdum, in dignissim nulla vestibulum. Nunc sit
            amet finibus felis, ut egestas lacus. Sedan pellentesque quis magna
            eu vestibulum. Ut sed commodo neque. Morbi erat nis
          </p> */}
        </div>
        {/* <div className="check-lunch">
          <h4>Check &amp; Launch</h4>
          <div className="row">
            <div className="col-lg-6">
              <ul className="check">
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Aenean vitae pharetra leo. Aliquam pri.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  In aliquet, quam vitae blandit temporg.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Aenean volutpat enim vitae tincidunta.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Felis at venenatis porttitor, nunc arcua.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Maximus felis quam vitae tortor phase.
                </li>
                <li>
                  <i>
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/check.svg"}
                      alt="mages"
                    />
                  </i>
                  Nulla vulputate urna quis ex pellentes.
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <p>
                Interdum et malesuada fames ac ante ipsum tobal primis in
                faucibus. Etiam eu nibh elementum, tolda accumsan ona In eu
                ipsum fringilla, accumsan pur neque ac, aliquet nunc. In eu
                ipsum fringilla, wolan accumsan purus vel, pellentesque risus.
                Vivamusa purus at eros interdum, in dignissim nulla Ut sedal
                vestibulum. Nunc sit amet finibus felis, ut egestas lacus. Sedan
                commodo neque. Morbi erat niswole pellentesque quis magna eu
                vestibulum.{" "}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ProjectDetailsContent;
