import React from "react";

function HistoryArea() {
  return (
    <>
      <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>Vector 360 BD</h2>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-1.webp"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2021</span>
                  <h4>Our Story</h4>
                  <p>
                    VECTOR360 is a software development company who develop
                    softwares from scratch to comprehensive products. By
                    incorporating the Agile approach into our operations, we
                    ensure adaptability, transparency, frequent outputs, and
                    risk management. Agile offers various advantages to
                    stakeholders, consumers, and the team itself, including
                    improved product quality, better productivity, correct
                    prioritizing, and a highly collaborative work environment.
                    We are located in Dhaka, Bangladesh. We started in 2021 and
                    are gradually forwarding to the road of success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>Our Mission</h4>
                  <p>
                    At VECTOR360, we aim to unleash the power of innovation
                    through agile software solutions and develop high-performing
                    mobile and web applications. Our mission is to exceed client
                    objectives, emphasizing adaptability, transparency, and
                    collaboration.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-2.webp"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-3.webp"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2023</span>
                  <h4>Our Vision</h4>
                  <p>
                    VECTOR360 aims to become a leader in the software
                    development industry by setting new standards for
                    innovation. Our ultimate goal is to empower businesses with
                    top-notch services that help shape the future of tech
                    careers while creating transformative experiences for all
                    stakeholders. Technology should seamlessly integrate into
                    operations to drive limitless innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2024</span>
                  <h4>Core Values</h4>
                  <p>
                    Commitment to Excellence in Quality: Striving for the
                    highest standards in our products and processes,
                    continuously innovating and improving. <br />
                    Embracing Empathy and Support: Creating a culture of
                    empathy, respect, and proactive assistance to meet and
                    anticipate the needs of customers and colleagues. <br />
                    Promoting Growth and Accountability: Encouraging continuous
                    personal and professional development while maintaining
                    strong accountability for our actions and outcomes. <br />
                    Fostering a Collaborative and Joyful Environment:
                    Cultivating a vibrant community that values open
                    communication, teamwork, and shared joy in our achievements
                    and endeavors. <br />
                    Cultivating a Culture of Fearless Exploration: Encouraging a
                    mindset of curiosity and experimentation, where team members
                    are empowered to explore innovative ideas without fear of
                    failure.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-4.webp"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoryArea;
