import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import PrincipalObjectivesWrap from "./PrincipalObjectivesWrap";

export default function PrincipalObjectives() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb
          pageName="Principal Objectives"
          pageTitle="Principal Objectives"
        />
        <PrincipalObjectivesWrap />
        <SubscribArea />
      </div>
    </>
  );
}
