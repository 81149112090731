import analysis from "../../../assets/img/principalObjectives/analysis.jpg";
import development from "../../../assets/img/principalObjectives/Development.jpg";
import project from "../../../assets/img/principalObjectives/project.jpg";
import research from "../../../assets/img/principalObjectives/research.jpg";
import support from "../../../assets/img/principalObjectives/support.jpg";
export default function PrincipalObjectivesDetails() {
  return (
    <div className="service-details sec-mar-top">
      <div className="container">
        <div className="service-items sec-mar-top">
          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-details">
                  <h4>
                    <span>01</span>Development
                  </h4>
                  <p>
                    To introduce, promote, encourage, facilitate, and assist all
                    any form of private sector participation and other
                    non-government finance and other resources in the
                    development and improvement of infrastructure in Bangladesh.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details-thumb">
                  <img src={development} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6 or-2">
                <div className="single-details-thumb">
                  <img src={project} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details">
                  <h4>
                    <span>02</span>Project
                  </h4>
                  <p>
                    To assist and advise ministries, government departments,
                    agencies, and other public sector bodies in Bangladesh with
                    the identification, prioritisation, preparation, evaluation,
                    awareness, and implementation of infrastructure projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-details">
                  <h4>
                    <span>03</span>Research
                  </h4>
                  <p>
                    To research, collect, publish, and provide technical,
                    regulatory, financial, statistical, demographic, geographic,
                    political, economic, procurement, and other relevant
                    information and policy.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details-thumb">
                  <img src={research} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6 or-2">
                <div className="single-details-thumb">
                  <img src={support} alt="" height={480} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details">
                  <h4>
                    <span>04</span>Support
                  </h4>
                  <p>
                    To develop, organise, and provide the public sector with
                    training, technical advice, and support, as well as written
                    and other media-stored materials, to enable the public
                    sector to involve the private sector in infrastructure
                    projects.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-details">
                  <h4>
                    <span>05</span>Analysis
                  </h4>
                  <p>
                    To investigate, research, advise, and advocate for the
                    public sector regarding the need for amendment. Introduce or
                    adapt any laws, policies, orders, and directives through
                    primary legislation, ministerial enactment, or otherwise.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details-thumb">
                  <img src={analysis} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
