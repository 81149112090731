const data = [
  {
    id: 1,
    title: "Reseveit",
    image: "img/project/mobile.jpg",
    category: "developing",
    details: "Mobile App",
  },
  {
    id: 2,
    title: "Reseveit",
    image: "img/project/reserveitweb.jpg",
    category: "web",
    details: "Website",
  },
  {
    id: 3,
    title: "Reserveit",
    image: "img/project/admin.jpg",
    category: "App",
    details: "Reserveit Admin Panel",
  },
  {
    id: 4,
    title: "Smart Krishok",
    image: "img/project/smartkrishok.webp",
    category: "web",
    details: "Smart Krishok User site",
  },
  {
    id: 5,
    title: "Website",
    image: "img/project/basecamp.png",
    category: "web",
    details: "FOY’S LAKE BASECAMP",
  },
  {
    id: 6,
    title: "Website",
    image: "img/project/thebase.png",
    category: "web",
    details: "THE BASECAMP BD",
  },
  {
    id: 7,
    title: "Website",
    image: "img/project/vps-s.webp",
    category: "web",
    details: "Virtual Property Studio",
  },
  // {
  //     id:7,
  //     title:"3D Design",
  //     image:"img/project/project-5.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:8,
  //     title:"3D Design",
  //     image:"img/project/project-1.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:9,
  //     title:"UI Kit",
  //     image:"img/project/project-2.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:10,
  //     title:"UI Kit",
  //     image:"img/project/project-4.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  //   {
  //     id: 11,
  //     title: "App",
  //     image: "img/project/project-3.jpg",
  //     category: "App",
  //     details: "Mobile Crypto Wallet",
  //   },
  // {
  //     id:12,
  //     title:"App",
  //     image:"img/project/project-1.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
];

export default data;
