import React from "react";
import ServiceCard from "./ServiceCard";
import {
  BrandIcon,
  CustomerManagementIcon,
  EnvironmentIcon,
  EnvironmentSocialIcon,
  MobileAppDevelopmentIcon,
  SoftwareDevelopmentIcon,
  SoftwareTestingIcon,
  TechRecrutingAgencyIcon,
  UiuxIcon,
  WebsiteDevelopmentIcon,
} from "../../svgs";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                Vector360: Transforming Ideas into Digital Realities. Our
                cutting-edge software development services will help you take
                your company to the next level
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image={<WebsiteDevelopmentIcon width={80} height={80} />}
                title="Website Development"
                details=" Achieve online success with our top-notch website development services."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image={<UiuxIcon width={80} height={80} />}
                title="UI/UX Design"
                details="Improve user engagement and boost conversions with our professional UI/UX design services.
                "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image={<SoftwareDevelopmentIcon width={80} height={80} />}
                title="Software Development"
                details="Achieve online success with our top-notch website development services."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image={<MobileAppDevelopmentIcon width={80} height={80} />}
                title="Mobile App Development"
                details="Unlock the full potential of your business with our professional mobile app development services.
                "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image={<TechRecrutingAgencyIcon width={80} height={80} />}
                title="Tech Recruiting Agency"
                details="Empowering tech careers through tailored connections, discover top-tier opportunities with our specialized recruiting agency service.
                "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image={<SoftwareTestingIcon width={80} height={80} />}
                title="Software Testing"
                details="I Ensure the quality and reliability of your software with our professional testing services.
                "
              />
             
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={7}
                image={<BrandIcon width={80} height={80} />}
                title="Branding"
                details="Vector360 offers professional branding services to help establish a strong brand identity for your business. Our team crafts cohesive branding strategies that resonate with your target audience 
                "
              />
             
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
            <ServiceCard
                serviceNumber={7}
                image={<CustomerManagementIcon width={80} height={80} />}
                title="Customer care management"
                details="Enhance your customer experience and streamline operations with Vector360 expert Business Process Outsourcing through our dedicated call center services.
                "
              />
              </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
            <ServiceCard
                serviceNumber={8}
                image={<EnvironmentSocialIcon width={80} height={80} />}
                title="Environmental and Social Impact Assessment"
                details="Explore our tailored Environmental and Social Impact Assessment solutions, designed to address both environmental conservation and community welfare for a holistic approach to project development.

                "
              />
              </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
            <ServiceCard
                serviceNumber={9}
                image={<EnvironmentIcon width={80} height={80} />}
                title="Environmental impact assessment
                "
                details="Vector360BD offers comprehensive Environmental Impact Assessment services, ensuring sustainable development and environmental compliance for your projects.

                "
              />
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
