export const careerData = [
	{
		id: "Information-technology",
		sectionTitle: "Information Technology",
		openingJob: [
			{
				id: 1,
				isOpen: false,
				title: "Node.js Developer",
				image: "/career/image1.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 2,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 3,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 4,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 5,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 6,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 7,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 8,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
		],
	},
	{
		id: "Creative-Department",
		sectionTitle: "Creative Department",
		openingJob: [
			{
				id: 9,
				isOpen: false,
				title: "Node.js Developer",
				image: "/career/image1.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 10,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
		],
	},
	{
		id: "Business-Department",
		sectionTitle: "Business Department",
		openingJob: [
			{
				id: 11,
				isOpen: false,
				title: "Node.js Developer",
				image: "/career/image1.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 12,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 13,
				isOpen: false,
				title: "Node.js Developer",
				image: "/career/image1.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 14,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 15,
				isOpen: false,
				title: "Node.js Developer",
				image: "/career/image1.png",
				status: "Currentely no opportunity available",
			},
			{
				id: 16,
				isOpen: false,
				title: "Back-End Developer",
				image: "/career/image2.png",
				status: "Currentely no opportunity available",
			},
		],
	},
];
