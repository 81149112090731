import {
	MobileAppDevelopmentIcon,
	SoftwareDevelopmentIcon,
	SoftwareTestingIcon,
	TechRecrutingAgencyIcon,
	UiuxIcon,
	WebsiteDevelopmentIcon,
} from "../../svgs";
import ServiceCard from "../service/ServiceCard";

export default function InternalMarketingDetails() {
	return (
		<>
			{" "}
			<div className="services-grid sec-mar">
				<div className="container">
					<div className="sec-title">
						<span>Our Solutions</span>
						<h2>Internal Marketing</h2>
						<p style={{ textAlign: "justify" }}>
							Vector360 offers a full range of marketing services for your
							business needs. Our team provides project development, design,
							capacity building, research and survey, transaction advisory,
							procurement, policy, and regulatory support. We help plan,
							execute, and manage projects to ensure they are delivered on time
							and within budget. Our design team creates visual content to
							effectively communicate your message. We offer training programs
							covering leadership, management, technology, and innovation to
							develop the skills and knowledge necessary for success in your
							industry. Our research and survey services provide insights into
							your market, customers, and competitors using the latest research
							methods. The transaction advisory team assists with complex
							transactions like mergers and acquisitions, divestitures, and
							joint ventures, providing guidance and support for informed
							decision-making. Our procurement services help source the goods
							and services needed to run your business efficiently. Our policy
							and regulatory support services help navigate the complex
							regulatory environment and ensure compliance with all relevant
							laws and regulations. Let Vector360 be your trusted partner in
							driving positive change and achieving your business goals.
						</p>
					</div>
					<div className="title-wrap"></div>
					{/* <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image={<WebsiteDevelopmentIcon width={80} height={80} />}
                title="Website Development"
                details=" Achieve online success with our top-notch website development services."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image={<UiuxIcon width={80} height={80} />}
                title="UI/UX Design"
                details="Improve user engagement and boost conversions with our professional UI/UX design services.
          "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image={<SoftwareDevelopmentIcon width={80} height={80} />}
                title="Software Development"
                details="Achieve online success with our top-notch website development services."
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image={<MobileAppDevelopmentIcon width={80} height={80} />}
                title="Mobile App Development"
                details="Unlock the full potential of your business with our professional mobile app development services.
          "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image={<TechRecrutingAgencyIcon width={80} height={80} />}
                title="Tech Recruiting Agency"
                details="Empowering tech careers through tailored connections, discover top-tier opportunities with our specialized recruiting agency service.
          "
              />
            </div>
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image={<SoftwareTestingIcon width={80} height={80} />}
                title="Software Testing"
                details="I Ensure the quality and reliability of your software with our professional testing services.
          "
              />
            </div>
          </div> */}
				</div>
			</div>
		</>
	);
}
