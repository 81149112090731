import { useParams } from "react-router-dom";
import AnimateLine from "../../../common/AnimateLine";
import Breadcrumb from "../../../common/Breadcrumb";
import SubscribArea from "../../../common/SubscribArea";
import JdView from "./components/JdView";

export default function ViewCareer() {
	const { id } = useParams();

	return (
		<div className="creasoft-wrap">
			<AnimateLine />
			<Breadcrumb pageTitle="Job Description" pageName="Career" />
			<JdView id={id} />
			<SubscribArea />
		</div>
	);
}
